import React, { useState, useEffect } from 'react';

import Cookies from 'js-cookie';

import './CookieBanner.scss';

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const cookieConsent = Cookies.get('cookieConsent');

    if (!cookieConsent) {
      setShowBanner(true);
    }
  }, []);

  const handleAgree = () => {
    Cookies.set('cookieConsent', 'true', { expires: 365 });
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <div className="cookie-container">
      <span className="body-2">
        We use cookies on our website to improve its functionality and to enhance your user experience. We also use
        cookies for analytics. If you continue to browse this website, we will assume you agree that we can place
        cookies on your device. If you would like to, you can change your cookie options at any time. For more details,
        please read our{' '}
        <a href="/privacy-and-cookies-policy" className="text-link">
          Privacy and Cookies Policy
        </a>
        .
      </span>
      <button type="button" className="button-func button-2 button-yellow" onClick={handleAgree}>
        I agree
      </button>
    </div>
  );
};

export default CookieBanner;
