exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-blog-blog-post-tsx": () => import("./../../../src/templates/blog/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-blog-post-tsx" */),
  "component---src-templates-landings-adobe-commerce-magento-adobe-commerce-magento-tsx": () => import("./../../../src/templates/landings/adobe-commerce-magento/adobe-commerce-magento.tsx" /* webpackChunkName: "component---src-templates-landings-adobe-commerce-magento-adobe-commerce-magento-tsx" */),
  "component---src-templates-landings-ai-ml-consulting-ai-ml-consulting-tsx": () => import("./../../../src/templates/landings/ai-ml-consulting/ai-ml-consulting.tsx" /* webpackChunkName: "component---src-templates-landings-ai-ml-consulting-ai-ml-consulting-tsx" */),
  "component---src-templates-landings-blog-blog-tsx": () => import("./../../../src/templates/landings/blog/blog.tsx" /* webpackChunkName: "component---src-templates-landings-blog-blog-tsx" */),
  "component---src-templates-landings-business-intelligence-business-intelligence-tsx": () => import("./../../../src/templates/landings/business-intelligence/business-intelligence.tsx" /* webpackChunkName: "component---src-templates-landings-business-intelligence-business-intelligence-tsx" */),
  "component---src-templates-landings-chatbot-development-services-chatbot-development-services-tsx": () => import("./../../../src/templates/landings/chatbot-development-services/chatbot-development-services.tsx" /* webpackChunkName: "component---src-templates-landings-chatbot-development-services-chatbot-development-services-tsx" */),
  "component---src-templates-landings-cloud-aws-cloud-aws-tsx": () => import("./../../../src/templates/landings/cloud-aws/cloud-aws.tsx" /* webpackChunkName: "component---src-templates-landings-cloud-aws-cloud-aws-tsx" */),
  "component---src-templates-landings-cloud-azure-cloud-azure-tsx": () => import("./../../../src/templates/landings/cloud-azure/cloud-azure.tsx" /* webpackChunkName: "component---src-templates-landings-cloud-azure-cloud-azure-tsx" */),
  "component---src-templates-landings-cloud-devops-enablement-services-cloud-devops-enablement-services-tsx": () => import("./../../../src/templates/landings/cloud-devops-enablement-services/cloud-devops-enablement-services.tsx" /* webpackChunkName: "component---src-templates-landings-cloud-devops-enablement-services-cloud-devops-enablement-services-tsx" */),
  "component---src-templates-landings-cloud-saas-cloud-saas-tsx": () => import("./../../../src/templates/landings/cloud-saas/cloud-saas.tsx" /* webpackChunkName: "component---src-templates-landings-cloud-saas-cloud-saas-tsx" */),
  "component---src-templates-landings-company-overview-company-overview-tsx": () => import("./../../../src/templates/landings/company-overview/company-overview.tsx" /* webpackChunkName: "component---src-templates-landings-company-overview-company-overview-tsx" */),
  "component---src-templates-landings-computer-vision-development-computer-vision-development-tsx": () => import("./../../../src/templates/landings/computer-vision-development/computer-vision-development.tsx" /* webpackChunkName: "component---src-templates-landings-computer-vision-development-computer-vision-development-tsx" */),
  "component---src-templates-landings-contact-us-contact-us-tsx": () => import("./../../../src/templates/landings/contact-us/contact-us.tsx" /* webpackChunkName: "component---src-templates-landings-contact-us-contact-us-tsx" */),
  "component---src-templates-landings-crm-consulting-crm-consulting-tsx": () => import("./../../../src/templates/landings/crm-consulting/crm-consulting.tsx" /* webpackChunkName: "component---src-templates-landings-crm-consulting-crm-consulting-tsx" */),
  "component---src-templates-landings-custom-software-development-custom-software-development-tsx": () => import("./../../../src/templates/landings/custom-software-development/custom-software-development.tsx" /* webpackChunkName: "component---src-templates-landings-custom-software-development-custom-software-development-tsx" */),
  "component---src-templates-landings-data-analytics-services-data-analytics-services-tsx": () => import("./../../../src/templates/landings/data-analytics-services/data-analytics-services.tsx" /* webpackChunkName: "component---src-templates-landings-data-analytics-services-data-analytics-services-tsx" */),
  "component---src-templates-landings-dedicated-software-development-dedicated-software-development-tsx": () => import("./../../../src/templates/landings/dedicated-software-development/dedicated-software-development.tsx" /* webpackChunkName: "component---src-templates-landings-dedicated-software-development-dedicated-software-development-tsx" */),
  "component---src-templates-landings-developers-for-hire-developers-for-hire-tsx": () => import("./../../../src/templates/landings/developers-for-hire/developers-for-hire.tsx" /* webpackChunkName: "component---src-templates-landings-developers-for-hire-developers-for-hire-tsx" */),
  "component---src-templates-landings-devops-consulting-services-devops-consulting-services-tsx": () => import("./../../../src/templates/landings/devops-consulting-services/devops-consulting-services.tsx" /* webpackChunkName: "component---src-templates-landings-devops-consulting-services-devops-consulting-services-tsx" */),
  "component---src-templates-landings-ecommerce-development-ecommerce-development-tsx": () => import("./../../../src/templates/landings/ecommerce-development/ecommerce-development.tsx" /* webpackChunkName: "component---src-templates-landings-ecommerce-development-ecommerce-development-tsx" */),
  "component---src-templates-landings-elearning-software-development-elearning-software-development-tsx": () => import("./../../../src/templates/landings/elearning-software-development/elearning-software-development.tsx" /* webpackChunkName: "component---src-templates-landings-elearning-software-development-elearning-software-development-tsx" */),
  "component---src-templates-landings-energy-utilities-energy-utilities-tsx": () => import("./../../../src/templates/landings/energy-utilities/energy-utilities.tsx" /* webpackChunkName: "component---src-templates-landings-energy-utilities-energy-utilities-tsx" */),
  "component---src-templates-landings-engagement-models-engagement-models-tsx": () => import("./../../../src/templates/landings/engagement-models/engagement-models.tsx" /* webpackChunkName: "component---src-templates-landings-engagement-models-engagement-models-tsx" */),
  "component---src-templates-landings-erp-consulting-erp-consulting-tsx": () => import("./../../../src/templates/landings/erp-consulting/erp-consulting.tsx" /* webpackChunkName: "component---src-templates-landings-erp-consulting-erp-consulting-tsx" */),
  "component---src-templates-landings-finance-finance-tsx": () => import("./../../../src/templates/landings/finance/finance.tsx" /* webpackChunkName: "component---src-templates-landings-finance-finance-tsx" */),
  "component---src-templates-landings-full-it-project-outsourcing-full-it-project-outsourcing-tsx": () => import("./../../../src/templates/landings/full-it-project-outsourcing/full-it-project-outsourcing.tsx" /* webpackChunkName: "component---src-templates-landings-full-it-project-outsourcing-full-it-project-outsourcing-tsx" */),
  "component---src-templates-landings-healthcare-healthcare-tsx": () => import("./../../../src/templates/landings/healthcare/healthcare.tsx" /* webpackChunkName: "component---src-templates-landings-healthcare-healthcare-tsx" */),
  "component---src-templates-landings-how-we-work-how-we-work-tsx": () => import("./../../../src/templates/landings/how-we-work/how-we-work.tsx" /* webpackChunkName: "component---src-templates-landings-how-we-work-how-we-work-tsx" */),
  "component---src-templates-landings-industries-industries-tsx": () => import("./../../../src/templates/landings/industries/industries.tsx" /* webpackChunkName: "component---src-templates-landings-industries-industries-tsx" */),
  "component---src-templates-landings-insurance-software-development-insurance-software-development-tsx": () => import("./../../../src/templates/landings/insurance-software-development/insurance-software-development.tsx" /* webpackChunkName: "component---src-templates-landings-insurance-software-development-insurance-software-development-tsx" */),
  "component---src-templates-landings-it-infrastructure-management-it-infrastructure-management-tsx": () => import("./../../../src/templates/landings/it-infrastructure-management/it-infrastructure-management.tsx" /* webpackChunkName: "component---src-templates-landings-it-infrastructure-management-it-infrastructure-management-tsx" */),
  "component---src-templates-landings-java-development-services-java-development-services-tsx": () => import("./../../../src/templates/landings/java-development-services/java-development-services.tsx" /* webpackChunkName: "component---src-templates-landings-java-development-services-java-development-services-tsx" */),
  "component---src-templates-landings-kotlin-development-services-kotlin-development-services-tsx": () => import("./../../../src/templates/landings/kotlin-development-services/kotlin-development-services.tsx" /* webpackChunkName: "component---src-templates-landings-kotlin-development-services-kotlin-development-services-tsx" */),
  "component---src-templates-landings-logistics-logistics-tsx": () => import("./../../../src/templates/landings/logistics/logistics.tsx" /* webpackChunkName: "component---src-templates-landings-logistics-logistics-tsx" */),
  "component---src-templates-landings-maintenance-and-support-maintenance-and-support-tsx": () => import("./../../../src/templates/landings/maintenance-and-support/maintenance-and-support.tsx" /* webpackChunkName: "component---src-templates-landings-maintenance-and-support-maintenance-and-support-tsx" */),
  "component---src-templates-landings-manufacturing-manufacturing-tsx": () => import("./../../../src/templates/landings/manufacturing/manufacturing.tsx" /* webpackChunkName: "component---src-templates-landings-manufacturing-manufacturing-tsx" */),
  "component---src-templates-landings-marketplace-development-services-marketplace-development-services-tsx": () => import("./../../../src/templates/landings/marketplace-development-services/marketplace-development-services.tsx" /* webpackChunkName: "component---src-templates-landings-marketplace-development-services-marketplace-development-services-tsx" */),
  "component---src-templates-landings-mobile-app-development-mobile-app-development-tsx": () => import("./../../../src/templates/landings/mobile-app-development/mobile-app-development.tsx" /* webpackChunkName: "component---src-templates-landings-mobile-app-development-mobile-app-development-tsx" */),
  "component---src-templates-landings-mvp-development-mvp-development-tsx": () => import("./../../../src/templates/landings/mvp-development/mvp-development.tsx" /* webpackChunkName: "component---src-templates-landings-mvp-development-mvp-development-tsx" */),
  "component---src-templates-landings-net-development-services-net-development-services-tsx": () => import("./../../../src/templates/landings/net-development-services/net-development-services.tsx" /* webpackChunkName: "component---src-templates-landings-net-development-services-net-development-services-tsx" */),
  "component---src-templates-landings-php-development-services-php-development-services-tsx": () => import("./../../../src/templates/landings/php-development-services/php-development-services.tsx" /* webpackChunkName: "component---src-templates-landings-php-development-services-php-development-services-tsx" */),
  "component---src-templates-landings-portal-development-portal-development-tsx": () => import("./../../../src/templates/landings/portal-development/portal-development.tsx" /* webpackChunkName: "component---src-templates-landings-portal-development-portal-development-tsx" */),
  "component---src-templates-landings-portfolio-portfolio-tsx": () => import("./../../../src/templates/landings/portfolio/portfolio.tsx" /* webpackChunkName: "component---src-templates-landings-portfolio-portfolio-tsx" */),
  "component---src-templates-landings-privacy-and-cookies-policy-privacy-and-cookies-policy-tsx": () => import("./../../../src/templates/landings/privacy-and-cookies-policy/privacy-and-cookies-policy.tsx" /* webpackChunkName: "component---src-templates-landings-privacy-and-cookies-policy-privacy-and-cookies-policy-tsx" */),
  "component---src-templates-landings-professional-services-software-development-professional-services-software-development-tsx": () => import("./../../../src/templates/landings/professional-services-software-development/professional-services-software-development.tsx" /* webpackChunkName: "component---src-templates-landings-professional-services-software-development-professional-services-software-development-tsx" */),
  "component---src-templates-landings-python-development-services-python-development-services-tsx": () => import("./../../../src/templates/landings/python-development-services/python-development-services.tsx" /* webpackChunkName: "component---src-templates-landings-python-development-services-python-development-services-tsx" */),
  "component---src-templates-landings-quality-management-quality-management-tsx": () => import("./../../../src/templates/landings/quality-management/quality-management.tsx" /* webpackChunkName: "component---src-templates-landings-quality-management-quality-management-tsx" */),
  "component---src-templates-landings-react-native-development-services-react-native-development-services-tsx": () => import("./../../../src/templates/landings/react-native-development-services/react-native-development-services.tsx" /* webpackChunkName: "component---src-templates-landings-react-native-development-services-react-native-development-services-tsx" */),
  "component---src-templates-landings-retail-retail-tsx": () => import("./../../../src/templates/landings/retail/retail.tsx" /* webpackChunkName: "component---src-templates-landings-retail-retail-tsx" */),
  "component---src-templates-landings-ruby-on-rails-development-services-ruby-on-rails-development-services-tsx": () => import("./../../../src/templates/landings/ruby-on-rails-development-services/ruby-on-rails-development-services.tsx" /* webpackChunkName: "component---src-templates-landings-ruby-on-rails-development-services-ruby-on-rails-development-services-tsx" */),
  "component---src-templates-landings-security-management-security-management-tsx": () => import("./../../../src/templates/landings/security-management/security-management.tsx" /* webpackChunkName: "component---src-templates-landings-security-management-security-management-tsx" */),
  "component---src-templates-landings-services-quality-assurance-services-quality-assurance-tsx": () => import("./../../../src/templates/landings/services-quality-assurance/services-quality-assurance.tsx" /* webpackChunkName: "component---src-templates-landings-services-quality-assurance-services-quality-assurance-tsx" */),
  "component---src-templates-landings-services-we-offer-services-we-offer-tsx": () => import("./../../../src/templates/landings/services-we-offer/services-we-offer.tsx" /* webpackChunkName: "component---src-templates-landings-services-we-offer-services-we-offer-tsx" */),
  "component---src-templates-landings-software-product-development-software-product-development-tsx": () => import("./../../../src/templates/landings/software-product-development/software-product-development.tsx" /* webpackChunkName: "component---src-templates-landings-software-product-development-software-product-development-tsx" */),
  "component---src-templates-landings-system-integration-services-system-integration-services-tsx": () => import("./../../../src/templates/landings/system-integration-services/system-integration-services.tsx" /* webpackChunkName: "component---src-templates-landings-system-integration-services-system-integration-services-tsx" */),
  "component---src-templates-landings-ux-ui-design-services-ux-ui-design-services-tsx": () => import("./../../../src/templates/landings/ux-ui-design-services/ux-ui-design-services.tsx" /* webpackChunkName: "component---src-templates-landings-ux-ui-design-services-ux-ui-design-services-tsx" */),
  "component---src-templates-landings-web-application-development-web-application-development-tsx": () => import("./../../../src/templates/landings/web-application-development/web-application-development.tsx" /* webpackChunkName: "component---src-templates-landings-web-application-development-web-application-development-tsx" */),
  "component---src-templates-portfolio-portfolio-post-tsx": () => import("./../../../src/templates/portfolio/portfolio-post.tsx" /* webpackChunkName: "component---src-templates-portfolio-portfolio-post-tsx" */)
}

