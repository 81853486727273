import React from 'react';

import { GatsbyBrowser } from 'gatsby';

import CookieBanner from '@/components/Blocks/CookieBanner/CookieBanner';

import './src/styles/index.scss';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => (
  <>
    {element}
    <CookieBanner />
  </>
);
